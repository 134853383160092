* { font-family: SquareNeo-Regular, serif; }
.App { width: 100%; min-height: 100vh; display: flex; justify-content: center; align-items: center; flex-direction: column; background: white; }
.Header { height: 150px; width: 100%;  max-width:1000px; padding: 20px 10px; display:flex; align-items: center; justify-content: flex-start; flex-direction: row; color: #FFF; box-sizing: border-box; }
.Header img { width: 150px;  }
.box {text-align: center; width: 200px; border-radius: 8px; background-color: black; color: white; font-weight: bold; font-size: 16px; display:flex; padding: 25px 10px; height:10px; margin: 0 10px; justify-content: center; align-items: center; cursor: pointer; }
.min-box{display: none;}
.Contents-Container { position: relative; z-index: 1; width: 100%; min-height: 100vh; display:flex; align-items: center; flex-direction: column; }
.Contents-Box { width:1000px; box-sizing: border-box; padding: 20px 50px; background: white; box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%), 0 16px 32px rgb(0 0 0 / 7%), 0 32px 64px rgb(0 0 0 / 7%); margin-bottom:50px; }
.Footer { width:100%; font-weight: bold; display: flex; justify-content: center; align-items: center; font-size:15px; color:white; background: black; padding: 15px 0; margin-top:150px; text-align: center; }

.Content-Title{ width:100%; height:100px; box-sizing: border-box; display: flex; align-items: center; font-size: 35px; font-family: SquareNeo-EB, sans-serif; margin-bottom:10px; }
.Content-Input-Section{ width:100%; display:flex; flex-wrap: wrap; justify-content: space-between; }

.Content-Date-Box{ width:100%; height:40px; margin: 10px 0 20px 0; display:flex; }
.Content-Date{ height:40px; padding: 0 20px; margin-right: 15px; display:flex; justify-content: center; align-items: center;  color: #BDBDBD; box-sizing: border-box; font-size: 15px; border-radius: 20px; border: 1px #E6E6E6 solid; cursor: pointer; font-family: SquareNeo-Bold, sans-serif; transition: all .3s; }
.Content-Date-Selected{ background-color: black; color: white; }

.Content-Input-Box{ width:47%; margin-top:15px; display:flex; flex-direction: column; }
.Content-Input-Title{ margin-bottom: 15px; font-size: 15px; margin-left: 5px; font-family: SquareNeo-Bold, sans-serif; }
.Content-Input { display:flex; align-items: center; margin-bottom: 30px; }
.Content-Input input { width: calc(100% - 40px); box-sizing: border-box; height: 40px; outline: none; margin:0; padding:0 15px; transition: all .3s; border-radius: 20px; font-size:15px; }
.Content-Input .blur { border: 1px #E6E6E6 solid; }
.Content-Input .focus { border: 1px black solid; }
.Content-Input span { width: 30px; font-size: 15px; margin-left: 5px; }

.Content-Submit-Box { width:100%; margin: 100px 0 10px 0; height: 52px; display:flex; justify-content: center; align-items: center; }
.Content-Submit-Button { width:100%; height:52px; display: flex; justify-content: center; align-items: center; font-size: 16px; background-color: black; color: white; font-family: SquareNeo-EB, sans-serif; cursor:pointer; border-radius: 25px; }

.Result-Des-Content { width:100%; display:flex; justify-content: center; align-items: center; }
.Result-Des-Box{ width:48%; display:flex; justify-content: center; align-items: center; flex-direction: column; }
.Result-Des-Title { padding: 10px 0; font-size: 20px; font-family: SquareNeo-Bold, sans-serif; margin-bottom: 12px; }
.Result-Des-Value { font-size: 35px; font-family: SquareNeo-EB, sans-serif; width:100%; word-break:break-all; display: flex; justify-content: center; }
.Value-Special{ color: black; }

.Result-Table { width:100%; display: flex; flex-direction: column; margin: 50px 0; }
.Result-Table-Thead { width:100%; min-height:50px; display: flex; justify-content: center; align-items: center; border-bottom: 2px #BDBDBD solid; }
.Result-Table-Thead div { font-size: 15px; font-family: SquareNeo-Bold, sans-serif; display: flex; justify-content: center; align-items: center; word-break:break-all; }
.Result-Table-Thead-Td1, .Result-Table-Td1{ width: 7%; }
.Result-Table-Thead-Td2, .Result-Table-Td2{ width: 32%; }
.Result-Table-Thead-Td3, .Result-Table-Td3{ width: 42%; }
.Result-Table-Thead-Td4, .Result-Table-Td4{ width: 19%; }
.Result-Table-Tbody{ width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column; }
.Result-Table-Tr{ width: 100%; display: flex; min-height: 45px; padding:5px 0; justify-content: center; align-items: center; border-bottom: 1px #D8D8D8 solid; }
.Result-Table-Tr div { font-size: 14px; font-family: SquareNeo-Bold, sans-serif; display: flex; justify-content: center; align-items: center; word-break:break-all; }

@media(max-width: 1000px) {
  .Contents-Box { padding: 20px; width:90%; }
}

@media(max-width: 900px) {
  .Contents-Box { padding: 20px 15px; }
  .Result-Table-Thead div { font-size: 14px; }
  .top-box{display: none}
  .Header{ padding: 30px 10px; height: 100px; display: none; }
  .Contents-Box { padding: 20px; width:100%; }
  .Contents-Box { box-shadow: none; }
  .Footer { margin-top: 0; }
  .min-box{display: flex; width: calc(100% - 40px);  flex-direction: row; margin: 10px 20px}
}
@media(max-width: 700px) {

}
@media(max-width: 500px) {
  .Content-Date{ font-size: 13px; letter-spacing: -1px; }
  .Content-Input-Title{ font-size: 13px; letter-spacing: -1px; }
  .Contents-Box{ padding: 15px 20px; }
  .Result-Table-Thead div { font-size: 13px; letter-spacing: -1px; }
  .Result-Des-Title{ font-size:14px; letter-spacing: -1px; }
  .Result-Des-Value{ font-size:19px; letter-spacing: -1px; }
  .Result-Table-Thead div{ font-size: 12px; letter-spacing: -1px; }
  .Result-Table-Tr div{ font-size: 12px; letter-spacing: -1px; }
  .Content-Input span{ font-size: 13px; }
  .Content-Input input{ font-size: 13px; }
  .Content-Title{ height: auto; margin: 20px 0; }
}

@media(max-width: 390px) {
  .Result-Des-Value{ font-size: 17px; letter-spacing: -1px; }
  .box{font-size: 14px;}
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: "SquareNeo-Light";
  src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-aLt.otf");
}
@font-face {
  font-family: "SquareNeo-Regular";
  src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-bRg.otf");
}
@font-face {
  font-family: "SquareNeo-Bold";
  src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-cBd.otf");
}
@font-face {
  font-family: "SquareNeo-EB";
  src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-dEb.otf");
}
@font-face {
  font-family: "SquareNeo-Heavy";
  src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-eHv.otf");
}
